import React from "react"
import styled from "styled-components"
import useIsMobile from "../../../hooks/useIsMobile"
import { getAmountWithCommas } from "../../../utils/getAmountWithCommas"
import { SubTitle, Text } from "../../core/commonExports"
import { Loader } from "../../core/Loader"
import { OnMobile } from "../../DesktopMobileComps"
import useRegionalStats from "../../LeadFormSteps/hooks/useRegionalStats"
import useScrollableCards from "./useScrollableCards"

const StatDataItem = ({title="Customer Name", data="", allDataPresent=false}) => {
  let statIcon = ''
  if (title === "Fire Incidents") {
    statIcon = "🔥"
  } else if (title === "Burglary & Theft Incidents") {
    statIcon = "👮‍♂️"
  }

  const isCityStateField = title === "Your City" || title === "Your State"
  const isAddressField = title === "Address"

  const mobileMaxWidth = title === "Fire Incidents" ? "110px" : ""

  if (!data) {
    return null
  }

  return (
    <StatData mobileMaxWidth={mobileMaxWidth} mobileSpanFullRow={isCityStateField || isAddressField} mobileJustifyCenter={isCityStateField && allDataPresent}>
      {statIcon && <span style={{marginRight: "0.25rem", fontSize: "1.75rem"}}>{statIcon}</span>}
      <div style={{minWidth: isCityStateField ? "auto" : "100px", height: "fit-content"}}>
        <Text fontSize="12px" mfontSize="10px" lineHeight="15px" mlineHeight="12px" color="rgb(153, 153, 153)" style={{textAlign: "left", marginTop: "0", marginBottom: "6px"}}>
          {title}
        </Text>
        <Text fontSize="14px" mfontSize="12px" lineHeight="17px" mlineHeight="15px" color="#000" style={{textAlign: "left", margin: "6px 0", wordBreak: "break-word"}}>
          {!isNaN(data) ? getAmountWithCommas(data) : data}
        </Text>
      </div>
    </StatData>
  )
}

const RegionalStats = ({pincode, city, stateVal}) => {
  const {isMobile, isDetecting} = useIsMobile(true)

  const {nearestBusiness, incidentStats, loading} = useRegionalStats(
    pincode, 
    city, 
    stateVal, 
    !isMobile && !isDetecting // fetch the data here only on desktop (comp is only shown on desktop)
  )
  let totalCards = 0
  if (nearestBusiness) {
    totalCards++
  }
  if (incidentStats?.city) {
    totalCards++ 
  }
  if (incidentStats?.state) {
    totalCards++
  }

  const { handleCarouselDotClick, cardsRef: regionalStatsRef, scrollDotsRef } = useScrollableCards({
    totalCards,
    scrollMultiplier: totalCards,
    stateRefresh: loading,
  })

  const govtDataTag = (
    <Text fontSize="12px" mfontSize="10px" lineHeight="18px" mlineHeight="14px" color="rgba(153, 153, 153, 0.7)" style={{textAlign: "left", fontStyle: "italic", position: "absolute", right: "0.2rem", bottom: "0rem", marginBottom: "0.25rem"}}> 
      (Last 5 years Govt. Data)
    </Text>
  )

  const {fireIncidents: cityFireIncidents, burglaryTheftIncidents: cityBurglaryIncidents} = incidentStats?.city || {}
  const {fireIncidents: stateFireIncidents, burglaryTheftIncidents: stateBurglaryIncidents} = incidentStats?.state || {}

  if (loading) {
    return (
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    )
  }
    
  return (
    <>
      <StatsContainer ref={regionalStatsRef} className="cards">
        {nearestBusiness && (
          <StatContainer>
            <SubTitle color="#2A2B7F" fontSize="22px" lineHeight="26px" mfontSize="14px" mlineHeight="16px" desktopStyles={{marginBottom: "1rem"}} mobileStyles={{marginBottom: "0.5rem"}} style={{textTransform: "uppercase", textAlign: "center"}}>
              A business near you is insured with Verak!
            </SubTitle>
            <StatDataContainer columns="0.7fr 0.7fr 1fr">
              <StatDataItem title="Customer Name" data={nearestBusiness?.CustomerName} />
              <StatDataItem title="Business Name" data={nearestBusiness?.LegalEntityName} />
              <StatDataItem title="Address" data={nearestBusiness?.Address} />
            </StatDataContainer>
          </StatContainer>
        )}
        {incidentStats?.city && (
          <StatContainer>
            <SubTitle color="#2A2B7F" fontSize="22px" lineHeight="26px" mfontSize="14px" mlineHeight="16px" desktopStyles={{marginBottom: "1rem"}} mobileStyles={{marginBottom: "0.5rem"}} style={{textTransform: "uppercase", textAlign: "center"}}>
              Your risk factor is high
            </SubTitle>
            <StatDataContainer mobileColumns="0.75fr 1fr">
              <StatDataItem title="Your City" data={city} allDataPresent={cityFireIncidents && cityBurglaryIncidents} />
              <StatDataItem title="Fire Incidents" data={cityFireIncidents} />
              <StatDataItem title="Burglary & Theft Incidents" data={cityBurglaryIncidents} />
              {govtDataTag}
            </StatDataContainer>
          </StatContainer>
        )}
        {incidentStats?.state && (
          <StatContainer>
            <OnMobile>
              <SubTitle color="#2A2B7F" fontSize="22px" lineHeight="26px" mfontSize="14px" mlineHeight="16px" desktopStyles={{marginBottom: "1rem"}} mobileStyles={{marginBottom: "0.5rem"}} style={{textTransform: "uppercase", textAlign: "center"}}>
                Your risk factor is high
              </SubTitle>
            </OnMobile>
            <StatDataContainer mobileColumns="0.75fr 1fr">
              <StatDataItem title="Your State" data={stateVal} allDataPresent={stateFireIncidents && stateBurglaryIncidents} />
              <StatDataItem title="Fire Incidents" data={stateFireIncidents} />
              <StatDataItem title="Burglary & Theft Incidents" data={stateBurglaryIncidents} />
              {govtDataTag}
            </StatDataContainer>
          </StatContainer>
        )}
      </StatsContainer>
      <OnMobile style={{position: "relative", marginTop: "0.5rem"}}>
        {totalCards > 1 ? (
          <Text mfontSize="10px" mlineHeight="12px" color="rgb(195, 151, 249)" style={{position: "absolute", right: "0", top: "0", textAlign: "right", margin: "0"}}>
            Swipe right &gt;&gt;
          </Text>
        ) : null}
        <ScrollDotsContainer className="scroll-dots" ref={scrollDotsRef} style={{display: "flex", justifyContent: "center", gap: "6px"}} onClick={handleCarouselDotClick}>
          {new Array(totalCards)?.fill(0)?.map((card, cardNum) => (
            <ScrollDot data-id={cardNum + 1} className={`scroll-dot ${!cardNum ? "selected" : ""}`} />
          ))}
        </ScrollDotsContainer>
      </OnMobile>
    </>
  )
}

const ScrollDotsContainer = styled.div`
  .selected {
    background: #2A2B7F;
  }
`

const ScrollDot = styled.div`
  width: 10px; 
  height: 10px; 
  background: ${p => p.visible ? "#2A2B7F" : "#C397F9"}; 
  border-radius: 50%;
`

const StatData = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
  max-width: 185px; 
  height: fit-content; 
  @media screen and (max-width: 768px) {
    grid-column: ${p => p.mobileSpanFullRow ? "span 2" : "span 1"};
    max-width: ${p => p.mobileSpanFullRow ? "100%" : p.mobileMaxWidth || "185px"};
    justify-content: ${p => p.mobileJustifyCenter ? "center" : "flex-start"};
  }
`

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 8px;
    overflow: auto;
    margin: auto;
  }
  @media screen and (max-width: 450px) {
    width: min(450px, 100%);
    padding: 0 0.5rem;
    gap: 12px;
  }
`

const StatContainer = styled.div`
  @media screen and (max-width: 768px) {
    min-width: min(380px, 100%);
  }
`

const StatDataContainer = styled.div`
  position: relative;
  padding: 16px 22px;
  display: grid;
  grid-template-columns: ${p => p.columns || "0.5fr 0.75fr 1fr"};
  gap: 0px 16px;
  border: 2px solid #C397F9;
  border-radius: 8px;
  width: 500px;
  min-height: 100px; 

  @media screen and (max-width: 768px) {
    grid-template-columns: ${p => p.mobileColumns || "repeat(2, 1fr)"};
    padding: 6px;
    gap: 0px 6px;
    width: min(450px, 100%);
    min-height: 107px;
  }
`

const LoaderDiv = styled.div`
  margin: 0 12rem;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

export default RegionalStats