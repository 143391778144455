const serverUrl = process.env.GATSBY_SERVER_URL;

export const sendApiRequest = async (endpoint, body=null, method = 'POST', authWall=false, throwErr = true) => {

  let token = ''
  if (authWall) {
    token = sessionStorage.getItem('token')
  }
  const fetchMetaData = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : ''
    },
    body: body ? JSON.stringify(body) : null,
  }
  const res = await fetch(`${serverUrl}${endpoint}`, {
    method,
    ...fetchMetaData
  })
  if (!res.ok && throwErr) {
    throw new Error(res)
  }
  return res.json()
}