import React from "react"

const useScrollableCards = ({
  totalCards,
  // should be equal to totalCards when card spans the entire screen width
  scrollMultiplier,
  stateRefresh,
  fullWidthCards = true // do each of the cards span to the full width of the screen
}) => {
  const cardsRef = React.useRef(null)
  const scrollDotsRef = React.useRef(null)

  React.useEffect(() => {
    const handleScroll = (e) => {
      const scrollLeftOffset = e.target?.scrollLeft
      const scrollableWidth = e.target?.scrollWidth - e.target?.clientWidth
      const scrollPer = (scrollLeftOffset / scrollableWidth) * 100 || 0

      const scrollBtn = Math.min(Math.floor((scrollPer / 100) * scrollMultiplier), totalCards - 1)
      const scrollDots = scrollDotsRef?.current?.childNodes
      scrollDots?.forEach(el => el?.classList?.remove('selected'))
      scrollDots?.[scrollBtn]?.classList?.add('selected')
    }
    
    if (cardsRef?.current && scrollDotsRef?.current) {
      cardsRef?.current?.addEventListener(
        'scroll',
        handleScroll,
        {passive: true}
      )
    }
  }, [totalCards, cardsRef?.current, scrollDotsRef?.current, stateRefresh])

  const handleCarouselDotClick = e => {
    e.stopPropagation()
    const btnId = e.target?.getAttribute("data-id");
    const scrollableWidth = cardsRef?.current?.scrollWidth
    const scrollableWidthFraction = fullWidthCards ? 1 : 0.5
    const scrollPos = ((btnId - 1) / totalCards) * (scrollableWidth * scrollableWidthFraction)
    cardsRef?.current?.scroll({
      top: 0,
      left: scrollPos,
      behavior: 'smooth'
    })
  }

  return {
    handleCarouselDotClick,
    cardsRef,
    scrollDotsRef
  }
}

export default useScrollableCards