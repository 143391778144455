import { sendApiRequest } from "./apiUtils"

const throwErrors = false

export const getRegionalStatsData = async (cityValue, stateValue) => {
  if (!cityValue && !stateValue) return null
  const queryParams = new URLSearchParams()
  if (cityValue) queryParams.set('city', cityValue)
	if (stateValue) queryParams.set('state', stateValue)
  return await sendApiRequest(`/public/static/incident-stats?${queryParams}`, null, 'GET', false, throwErrors)
}

export const getNearestCustomerData = async (city, pincode) => {
  if (!city && !pincode) return null
  let url = `/public/nearest-customer`
  const queryParams = new URLSearchParams()
  if (city) queryParams.set('city', city)
  if (pincode) queryParams.set('pin', pincode)
  return await sendApiRequest(`${url}?${queryParams}`, null, 'GET', false, throwErrors)
}

export const getPincodeData = async (pincode) => {
  if (!pincode) return
  return await fetch(
    `https://api.postalpincode.in/pincode/${pincode}`
  ).then(res => res.json());
}

export const isInstantQuoteOccupancy = async (occupancy) => {
  if (!occupancy) return null
  const queryParams = new URLSearchParams()
  queryParams.set('occupancy', occupancy)
  return await sendApiRequest(`/public/static/is-instant-quote-occupancy?${queryParams}`, null, 'GET', false, throwErrors)
}