import React from "react"
import { getNearestCustomerData, getRegionalStatsData } from "../../../api/staticDataAPIs";

const useRegionalStats = (pincode, city, stateVal, fetchIfTrue = false) => {
  // {
  //   // CustomerName: "Carl Johnson",
  //   // LegalEntityName: "GTA San Andreas",
  //   // Address: "Groove Street, San Andreas"
  // }
  const [nearestBusiness, setNearestBusiness] = React.useState(null)
  // {
  //   // city: {
  //   //   fireIncidents: 4354,
  //   //   burglaryTheftIncidents: 2544
  //   // },
  //   // state: {
  //   //   fireIncidents: 1763,
  //   //   burglaryTheftIncidents: 1989
  //   // },
  // }
  const [incidentStats, setIncidentStats] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const fetchIncidentStats = async () => {
		if (!city && !stateVal) {
			setIncidentStats({})
			return
		}

		let cityValue = city?.toUpperCase()
		let stateValue = stateVal?.toUpperCase()
    if (cityValue?.includes('DELHI') || stateValue?.includes('DELHI')) {
			cityValue = 'DELHI'
		}

		const response = await getRegionalStatsData(cityValue, stateValue)
    if (response && response?.incidentStatistics) {
      setIncidentStats(response.incidentStatistics)
    }
	}

	const fetchNearestBusinessStats = async () => {
    const result = await getNearestCustomerData(city, pincode)
    if (result && result.cases?.length) {
      setNearestBusiness(result.cases?.[0])
    }
	}

  const fetchAllData = async () => {
    try {
      await Promise.all([
        fetchIncidentStats(),
        fetchNearestBusinessStats()
      ])
      setLoading(false)
    } catch (err) {
      setLoading(false)
      // if (!incidentStats && !nearestBusiness) {
      //   fallbackToPreviousScreen()
      // }
    }
  }

  React.useEffect(() => {
    if (fetchIfTrue && pincode && !incidentStats && !nearestBusiness) {
      fetchAllData()
    }
  }, [pincode, fetchIfTrue])

  return {nearestBusiness, incidentStats, loading}
}

export default useRegionalStats