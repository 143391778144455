import React from "react"

import "../../styles/loader.css";

export const Loader = (props) => {
    return (
        <div {...props}>
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}